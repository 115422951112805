export const BASE_HREF = '/';

export const GENERAL_TERMS_PATH = '/pdf/serenite/conditions_generales/Conditions_Generales_Offre_speciale_EFFY_SIPLEC-2.pdf';

export enum StepsPaths {
  NONE_ELIGIBLE = 'non-eligible',
  HOUSING_TYPE = 'logement-type',
  HOUSING_CONSTRUCTION = 'logement-construction',
  HOUSING_SURFACE = 'logement-surface',
  HOUSING_ENERGY = 'logement-energie',
  HOUSING_ENERGY_OIL = 'logement-energie/fioul',
  HOUSING_ENERGY_ELECTRIC = 'logement-energie/electrique',
  HOUSING_ENERGY_GAS = 'logement-energie/gaz',
  WORKS = 'travaux',
  INSULATION_TYPE = 'travaux/type-isolation',
  ATTICS_SURFACE = 'travaux/comble-surface',
  ATTICS_INSULATION_TYPE = 'travaux/isolation-type',
  WALLS_SURFACE = 'travaux/murs-surface',
  AIR_WATER_HEAT_PUMP_SURFACE = 'travaux/surface-chauffee',
  AIR_WATER_HEAT_PUMP_SANITARY_WATER = 'travaux/eau-chaude-sanitaire',
  AIR_WATER_HEAT_PUMP_EXTERIOR_SPACE = 'travaux/espace-exterieur',
  PROJECT_STATUS = 'statut-projet',
  TIMING_PROJECT = 'timing-projet',
  USER_STATUS = 'utilisateur-statut',
  HOUSEHOLD_COMPOSITION = 'menage-composition',
  HOUSEHOLD_INCOME = 'menage-revenus',
  USER_IDENTITY = 'utilisateur-identite',
  HOUSING_ZIP_CODE = 'code-postal-logement',
  USER_PHONE = 'utilisateur-telephone',
  USER_CREATION = 'utilisateur-creation',
  USER_LOGIN = 'utilisateur-connexion',
  CONFIRMATION = 'confirmation',
  WORK_ADDRESS = 'travaux-adresse',
}
